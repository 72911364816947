import React from 'react';
import { RequestStatus } from 'core/api/requests';
import { AxiosResponse } from 'axios';
import httpClient from 'services/http';

export interface UseRequestOptions<T> {
  url: string;
  defaultValue: T;
  launchOnMount?: boolean;
}

const defaults: UseRequestOptions<any> = {
  url: '',
  defaultValue: undefined,
  launchOnMount: true,
};

interface UseRequestState<T> {
  data: T;
  status: RequestStatus;
}

export type UseRequestResponse<T> = [
  UseRequestState<T>,
  (options: Partial<UseRequestOptions<T>>) => Promise<void>,
];

const useRequest = <T>(options: UseRequestOptions<T>): UseRequestResponse<T> => {
  const initialOptions = Object.assign(defaults, options);

  const [state, setState] = React.useState<UseRequestState<T>>({
    data: initialOptions.defaultValue,
    status: 'idle',
  });

  const request = async (o: Partial<UseRequestOptions<T>>): Promise<void> => {
    const config = Object.assign(initialOptions, o);

    setState({ ...state, status: 'loading' });

    try {
      const response: AxiosResponse<T> = await httpClient.get(config.url);
      setState({ data: response.data, status: 'success' });
    } catch (error) {
      setState({ ...state, status: 'failed' });
    }
  };

  React.useEffect(() => {
    if (initialOptions.launchOnMount) {
      request(initialOptions);
    }
    console.log('Mounted userequest', initialOptions);
  }, []);

  return [state, request];
};

export default useRequest;
