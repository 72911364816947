import React from 'react';
import classnames from 'classnames';
import { IncidentUnit, IIncident } from 'core/api/incident';

import './UnitReport.scss';
import { fromUTC } from 'utils/dates';
import { addUnitZoneDash } from 'utils/units';
import UnitReportLog from '../UnitReportLog/UnitReportLog';
import { IncidentContext } from '../IncidentContext';

interface IProps {
  unit: IncidentUnit;
}

const UnitReport: React.FC<IProps> = ({ unit }) => {
  const incident = React.useContext(IncidentContext);
  const startTime = fromUTC(unit.active_from);

  /**
   * Difference of time between the creation of the incident.
   */
  const activeInterval = startTime.minus(fromUTC(incident.created_at).toMillis());

  const styling = classnames({
    'm-unit-report': true,
    'm-unit-report--no-availability-system': !Boolean(unit.availability_system),
    [`m-unit-report--status-${unit.availability_status}`]:
      typeof unit.availability_status === 'string',
  });

  const logs = unit.logs.map((log) => <UnitReportLog key={log.id} log={log} unit={unit} />);

  return (
    <div className={styling}>
      <div className='m-unit-report__meta'>
        <span className='m-unit-report__meta--white'>
          +{activeInterval.toFormat(activeInterval.hour > 0 ? 'TT' : 'mm:ss')}
        </span>
        {startTime.toFormat('TT')}
      </div>
      <div className='m-unit-report__data'>
        <h3 className='m-unit-report__title'>
          {addUnitZoneDash(unit.unit_identifier)} ({unit.unit_role})
        </h3>
        <div className='m-unit-report__subtitle'>{unit.location_identifier}</div>
        <div className='m-unit-report__subtitle'>{unit.availability_system}</div>
      </div>

      {logs.length > 0 && <div className='m-unit-report__logs'>{logs}</div>}
    </div>
  );
};

export default UnitReport;
