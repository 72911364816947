import React from 'react';
import { useDispatch } from 'react-redux';

import Button, { IButtonProps } from './Button/Button';
import { doLogout } from 'store/session/sessionActions';

const LogoutButton: React.FC<IButtonProps> = ({ onClick, ...rest }) => {
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    dispatch(doLogout);

    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return <Button {...rest} onClick={handleClick} />;
};

export default LogoutButton;
