import React from 'react';
import { ReactComponent as Logo } from 'assets/images/wiib.svg';

import './NoIncidents.scss';

const NoIncidents: React.FC = () => (
  <div className='m-empty'>
    <div className='m-empty__inner'>
      <Logo className='m-empty__logo' />
      <span className='m-empty__msg'>Er zijn momenteel geen incidenten</span>
    </div>
  </div>
);

export default NoIncidents;
