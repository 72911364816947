import Echo from 'laravel-echo';
import io from 'socket.io-client';
import { socketURL, isDev } from './env';

(window as any).io = io;

export type SocketCallback<T = any> = (payload: T) => any;

class Communication {
  public echo: Echo;

  public constructor() {
    this.echo = new Echo({
      broadcaster: 'socket.io',
      host: socketURL,
      path: isDev ? undefined : '/ws/socket.io',
    });

    this.echo.connector.socket.on('connect', () => console.log('connected'));
    this.echo.connector.socket.on('disconnect', () => console.log('disconnect'));
    this.echo.connector.socket.on('reconnecting', () => console.log('reconnecting'));
  }

  public register(channel: string, event: string, callback: SocketCallback): void {
    this.echo.listen(channel, event, callback);
  }

  public unregister(channel: string): void {
    this.echo.leave(channel);
  }
}

const communication = new Communication();

export default communication;
