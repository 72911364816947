import { createSelector } from 'reselect';

import { IRootState } from '../store';
import { ISessionTokens } from './sessionReducer';
import { areSessionTokens } from 'utils/guard';
import { PersistState } from 'redux-persist';

/**
 * Checks if the session reducer has been hydrated by redux-persist. This can be used to check if a user can be logged in or not.
 */
export const sessionHydrated = createSelector<IRootState, PersistState, boolean>(
  (state) => state.session._persist,
  (persistState) => persistState.rehydrated,
);

export const isLoggedIn = createSelector<IRootState, ISessionTokens | undefined, boolean>(
  (state) => state.session.tokens,
  (tokens) => areSessionTokens(tokens),
);
