import React from 'react';
import useEvent from 'hooks/useEvent';

import { IIncident } from 'core/socket/incident';
import { IncidentsEvent } from 'core/socket/events';

import NoIncidents from 'components/NoIncidents';
import IncidentGrid from 'containers/IncidentGrid/IncidentGrid';

const Dashboard: React.FC = () => {
  const [incidents, setIncidents] = React.useState<IIncident[]>([]);

  useEvent<IncidentsEvent>('incidents', '.App\\Events\\Incidents', (event) => {
    console.log('event:', event);
    setIncidents(event.incidents);
  });

  const empty = incidents.length === 0;

  return (
    <main className='o-main'>
      {empty ? <NoIncidents /> : <IncidentGrid incidents={incidents} />}
    </main>
  );
};

export default Dashboard;
