import React from 'react';
import classnames from 'classnames';

import { IIncident } from 'core/socket/incident';
import IncidentGridItem from './IncidentGridItem/IncidentGridItem';
import { sortRows } from 'services/sorting';

import './IncidentGrid.scss';

interface IProps {
  incidents: IIncident[];
}

const IncidentGrid: React.FC<IProps> = (props) => {
  const size = props.incidents.length;
  let phase: 1 | 2 = 1;

  if (size < 10) {
    phase = 1;
  } else if (size >= 10) {
    phase = 2;
  }

  const styling = classnames({
    't-incident-grid': true,
    't-incident-grid--phase-1': phase === 1,
    't-incident-grid--phase-2': phase === 2,
  });

  const gridItems = sortRows(props.incidents, 'created_at', 'desc').map((incident) => (
    <IncidentGridItem key={incident.id} incident={incident} phase={phase} />
  ));

  return <div className={styling}>{gridItems}</div>;
};

export default IncidentGrid;
