/** @format */

import { ActionCreator, ActionType, IActionCreator } from 'store/actions';
import { ITokenResponse } from 'core/api/auth';
import { IUser } from 'core/api/user';

export interface ILoginActionPayload {
  token: ITokenResponse;
  user: IUser;
}
export const doLogin: ActionCreator<ActionType.LOGIN, ILoginActionPayload> = (payload) => ({
  type: ActionType.LOGIN,
  payload: payload,
});

export const doLogout: IActionCreator<ActionType.LOGOUT, void> = {
  type: ActionType.LOGOUT,
  payload: undefined,
};
