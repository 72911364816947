import React from 'react';
import classnames from 'classnames';

import { IIncident } from 'core/socket/incident';
import Incident from 'components/Incident';

interface IProps {
  incident: IIncident;
  phase: 1 | 2;
}

const IncidentGridItem: React.FC<IProps> = ({ incident, phase }) => {
  const units = incident.unit_roles_active.length;

  const span2 = units > 5 && units <= 18;

  const span3 = (phase === 2 && units > 8 && units <= 12) || units > 18;

  const styling = classnames({
    't-incident-grid__item': true,
    't-incident-grid__item--span-2': span2 && !span3,
    't-incident-grid__item--span-3': span3,
  });

  return (
    <div className={styling} key={incident.id}>
      <Incident incident={incident} />
    </div>
  );
};

export default IncidentGridItem;
