import { REHYDRATE as rehydrateActionType } from 'redux-persist';
import { takeEvery } from 'redux-saga/effects';
import { ActionType, IAction } from './actions';

/* Sagas */
import * as sessionSagas from './session/sessionSagas';
import { loadTokens } from './persist/rehydrate';

/**
 * redux-saga doesn't support enum values by default,
 * so we're changing it to a boolean instead.
 *
 * @param type ActionType to check for.
 */
const actionTypeMatch = (type: ActionType) => (action: IAction): boolean => action.type === type;

function* mySaga(): Generator {
  yield takeEvery(rehydrateActionType, loadTokens);
  yield takeEvery(actionTypeMatch(ActionType.LOGIN), sessionSagas.uponLogin);
  yield takeEvery(actionTypeMatch(ActionType.LOGOUT), sessionSagas.uponLogout);
}

export default mySaga;
