import { applyMiddleware, combineReducers, createStore, Store, Middleware, compose } from 'redux';
import { FormStateMap, reducer as formReducer } from 'redux-form';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';

import storage from 'redux-persist/lib/storage'; // This uses localStorage
import { persistReducer, persistStore } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import { readableActionTypes } from 'utils/redux';
import { IAction } from './actions';

import settingsReducer, { ISettingsState } from './settings/settingsReducer';
import sessionReducer, { ISessionState } from './session/sessionReducer';
import { isDev } from 'services/env';

/* Redux-saga */
import sagas from './sagas';

export interface IRootState {
  form: FormStateMap & PersistPartial;
  session: ISessionState & PersistPartial;
  settings: ISettingsState;
}

const logger = createLogger({
  actionTransformer: readableActionTypes,
  collapsed: false,
});

const sagaMiddleware = createSagaMiddleware();

const appliedMiddleware: Middleware[] = [sagaMiddleware];

if (isDev) {
  // appliedMiddleware.push(logger);
}

const rootReducer = combineReducers<IRootState>({
  form: persistReducer({ key: 'form', storage, blacklist: ['login'] }, formReducer),
  session: persistReducer({ key: 'session', storage }, sessionReducer),
  settings: settingsReducer,
});

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store: Store<IRootState, IAction> = createStore<IRootState, IAction, any, any>(
  rootReducer,
  composeEnhancers(applyMiddleware(...appliedMiddleware)),
);

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
