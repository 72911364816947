import { select } from 'redux-saga/effects';
import { setToken } from 'services/http';
import { IRootState } from '../store';
import { ISessionTokens } from 'store/session/sessionReducer';

export function* loadTokens(): Generator<unknown, void, ISessionTokens | undefined> {
  // Load token from state and set it in Axios
  const tokens = yield select((state: IRootState) => state.session.tokens);

  if (tokens) {
    setToken(tokens.accessToken);
  }
}
