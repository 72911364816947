import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './store/store';

import 'assets/scss/main.scss';
import 'services/applyColorScheme';

const target = document.getElementById('root');

const Application = (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(Application, target);
