import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { selectColorScheme } from 'store/settings/settingsSelectors';

import { history, routes } from 'services/routing';
import { ColorScheme } from 'services/colors';
import { isDev } from 'services/env';

import Menu from 'containers/Menu';
import Dashboard from 'pages/Dashboard/Dashboard';
import Statistics from 'pages/Statistics/Statistics';
import PrivateRoute from 'components/PrivateRoute';

const App: React.FC = () => {
  const colorScheme = useSelector(selectColorScheme);

  const styling = classnames({
    'o-app': true,
    [`o-app--scheme-${ColorScheme[colorScheme].toLowerCase()}`]: true,
  });

  return (
    <div className={styling}>
      <Router history={history}>
        <Switch>
          <Route exact path={routes.Dashboard} component={Dashboard} />
          <PrivateRoute exact path={routes.Statistics} component={Statistics} />

          <Redirect to={routes.Dashboard} />
        </Switch>

        <footer className='o-footer'>
          <Menu />
        </footer>
      </Router>
    </div>
  );
};

export default App;
