import { Mutator } from 'utils/redux';
import { ISettingsState } from './settingsReducer';
import { ColorScheme } from 'services/colors';

type SettingsMutator<P = void> = Mutator<ISettingsState, P>;

export const setColorScheme: SettingsMutator<ColorScheme> = (state, colorScheme) => ({
  ...state,
  colorScheme,
});
