import React from 'react';
import classnames from 'classnames';

import { IUnitRole } from 'core/socket/unitRoles';

import './Unit.scss';
import UnitContext from './UnitContext';

interface IProps {
  unit: IUnitRole;
}

const Unit: React.FC<IProps> = ({ unit }) => {
  const unitStatus = Number(unit.pivot.availability_status_id);
  const contextInfo: string[] = [];

  const noAvailabilitySystem = typeof unit.unit.location.availability_system_id !== 'number';

  const styling = classnames({
    'm-unit': true,
    'm-unit--unknown': unitStatus === 0 || unitStatus === 1,
    'm-unit--available': unitStatus === 2,
    'm-unit--unavailable': unitStatus === 3,
    'm-unit--no-availability-system': noAvailabilitySystem,
  });

  if (unit.unit.location) {
    contextInfo.push(unit.unit.location.gms_location.identifier.replace('02 ', ''));
  }

  if (unit.role.identifier) {
    contextInfo.push(unit.role.identifier);
  }

  if (typeof unit.pivot.availability_count === 'number') {
    contextInfo.push(unit.pivot.availability_count.toString());
  }

  return (
    <div className={styling}>
      <h1 className='m-unit__title'>
        {unit.unit.identifier.substring(0, 2)}-{unit.unit.identifier.substring(2)}
      </h1>

      <UnitContext information={contextInfo} />
    </div>
  );
};

export default Unit;
