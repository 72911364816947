import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LoginForm from './components/LoginForm';
import { isLoggedIn } from 'store/session/sessionSelectors';
import { routes } from 'services/routing';
import SessionInformation from '../../components/SessionInformation';
import LogoutButton from 'components/LogoutButton';
import MenuCard from 'components/MenuCard/MenuCard';

const Login: React.FC = () => {
  const loggedIn = useSelector(isLoggedIn);

  if (loggedIn) {
    return (
      <div>
        <nav className='m-menu__cards'>
          <MenuCard title='Dashboard' icon='dashboard' path={routes.Dashboard} />
          <MenuCard title='Statistieken' icon='bar-chart' path={routes.Statistics} />
        </nav>
        <SessionInformation />
      </div>
    );
  }

  return (
    <div>
      <h1>Login</h1>

      <hr />

      <LoginForm />
    </div>
  );
};

export default Login;
