import React from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'store/store';
import LogoutButton from './LogoutButton';

const SessionInformation: React.FC = () => {
  const user = useSelector((state: IRootState) => state.session.user);

  if (!user) {
    return null;
  }

  return (
    <div>
      Je bent ingelogd als {user.name} <LogoutButton>Uitloggen</LogoutButton>
    </div>
  );
};

export default SessionInformation;
