import { ActionType } from 'store/actions';
import { createReducer, IReducerHandlers } from 'utils/redux';
import { ColorScheme } from 'services/colors';

import * as settingsMutators from './settingsMutators';

export interface ISettingsState {
  colorScheme: ColorScheme;
}

const initialState: ISettingsState = {
  colorScheme: ColorScheme.Normal,
};

const handlers: IReducerHandlers<ISettingsState> = {
  [ActionType.TOGGLE_COLOR_SCHEME]: settingsMutators.setColorScheme,
};

export default createReducer<ISettingsState>(initialState, handlers);
