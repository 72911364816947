import React from 'react';
import classnames from 'classnames';

interface IProps extends React.SVGProps<SVGElement> {
  icon: string;
}

const SvgIcon: React.FunctionComponent<IProps> = ({ icon, className }) => {
  const styling = classnames({
    'a-icon': true,
    [`a-icon--${icon}`]: true,
    [className as string]: Boolean(className),
  });

  return (
    <svg className={styling}>
      <use xlinkHref={`#icon-${icon}`} />
    </svg>
  );
};

export default SvgIcon;
