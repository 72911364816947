import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router';

import { isLoggedIn, sessionHydrated } from 'store/session/sessionSelectors';
import { routes } from 'services/routing';

const PrivateRoute: React.FC<RouteProps> = ({ ...props }) => {
  const loggedIn = useSelector(isLoggedIn);
  const hydrated = useSelector(sessionHydrated);

  if (!hydrated) {
    return <div>spinner</div>;
  }

  if (!loggedIn) {
    return <Redirect to={routes.Dashboard} />;
  }

  return <Route {...props} />;
};

export default PrivateRoute;
