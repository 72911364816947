import React from 'react';
import SvgIcon from 'components/SvgIcon';

import './Menu.scss';
import AsideMenu from './components/AsideMenu';

const Menu: React.FC = () => {
  const [showAside, setShowAside] = React.useState(false);

  const closeAside = (): void => setShowAside(false);
  const toggleAside = (): void => setShowAside(!showAside);

  return (
    <nav className='m-menu'>
      <button className='m-menu__btn' onClick={toggleAside}>
        <SvgIcon className='m-menu__btn-icon' icon='cog' />
      </button>

      <AsideMenu visible={showAside} close={closeAside} />
    </nav>
  );
};

export default Menu;
