import { DateTime, Duration } from 'luxon';
import { Mutator } from 'utils/redux';
import { ISessionState } from './sessionReducer';
import { ILoginActionPayload } from './sessionActions';

type SessionMutator<P = void> = Mutator<ISessionState, P>;

export const mutateLogin: SessionMutator<ILoginActionPayload> = (state, payload) => ({
  ...state,
  user: payload.user,
  tokens: {
    accessToken: payload.token.access_token,
    refreshToken: payload.token.refresh_token,
    expires: DateTime.local()
      .plus(Duration.fromMillis(payload.token.expires_in * 1000))
      .toISO(),
  },
});
