import { ActionType } from 'store/actions';
import { createReducer, IReducerHandlers } from 'utils/redux';

import * as sessionMutators from './sessionMutators';
import { IUser } from 'core/api/user';

export interface ISessionTokens {
  expires: string;
  accessToken: string;
  refreshToken: string;
}

export interface ISessionState {
  tokens?: ISessionTokens;
  user?: IUser;
}

const initialState: ISessionState = {
  tokens: undefined,
  user: undefined,
};

const handlers: IReducerHandlers<ISessionState> = {
  [ActionType.LOGIN]: sessionMutators.mutateLogin,
  [ActionType.LOGOUT]: () => initialState,
};

export default createReducer<ISessionState>(initialState, handlers);
