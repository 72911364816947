import React from 'react';
import ReactDOM from 'react-dom';

type PortalHook = (
  wrapper: React.MutableRefObject<Element>,
) => (children: React.ReactNode) => React.ReactPortal;

const useRootLevelPortal: PortalHook = (wrapper) => {
  if (!wrapper.current) {
    throw new Error('Cannot create a portal due to the wrapper ref being empty');
  }

  React.useLayoutEffect(() => {
    const container = document.getElementsByClassName('o-app')[0];
    const element = wrapper.current;
    container.appendChild(element);

    return (): void => {
      container.removeChild(element);
    };
  }, [wrapper]);

  return (children): React.ReactPortal => ReactDOM.createPortal(children, wrapper.current);
};

export default useRootLevelPortal;
