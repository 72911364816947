import React from 'react';
import { IIncident } from 'core/api/incident';
import UnitReport from './UnitReport/UnitReport';
import SvgIcon from 'components/SvgIcon';
import { formatIncidentSubtitle } from 'utils/incidents';
import { fromUTC } from 'utils/dates';
import { IncidentContext } from './IncidentContext';

interface IProps {
  incident: IIncident;
}

const IncidentReport: React.FC<IProps> = ({ incident }) => {
  const units = incident.units.map((unit) => <UnitReport key={unit.unit_identifier} unit={unit} />);

  const occurranceDate = fromUTC(incident.created_at).toFormat('TT');

  return (
    <IncidentContext.Provider value={incident}>
      <div className='m-timeline__meta'>
        <h3 className='m-timeline__occurrence'>{occurranceDate}</h3>

        <div className='m-timeline__ball'>
          <SvgIcon icon='info' />
        </div>

        <div className='m-timeline__detail-line' />
      </div>
      <div className='m-timeline__detail'>
        <div className='m-report'>
          <div className='m-report__subject'>
            {incident.place}, {formatIncidentSubtitle(incident)}
          </div>
          <div className='m-report__units'>{units}</div>
        </div>
      </div>
    </IncidentContext.Provider>
  );
};

export default IncidentReport;
