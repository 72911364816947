import React from 'react';
import classnames from 'classnames';
import { IncidentUnitLog, IncidentUnit, AvailabilityStatus } from 'core/api/incident';

import './UnitReportLog.scss';
import { fromUTC } from 'utils/dates';
import SvgIcon from 'components/SvgIcon';

interface IProps {
  log: IncidentUnitLog;
  unit: IncidentUnit;
}

type AvailabilityType = 'available' | 'unavailable' | 'unknown';

const AvailabilityStatistic: React.FC<{ weight: number; type: AvailabilityType }> = ({
  weight,
  type,
}) => {
  if (weight === 0) {
    return null;
  }

  const styling = classnames({
    'm-unit-log__stat': true,
    'm-unit-log__stat--padding': weight === 1,
    [`m-unit-log__stat--weight-${weight}`]: true,
    [`m-unit-log__stat--type-${type}`]: true,
  });

  return <div className={styling}>{weight}</div>;
};

const AvailableIcon: React.FC<{ status: AvailabilityStatus }> = ({ status }) => {
  const styling = classnames({
    'm-unit-log__availability-status': true,
    [`m-unit-log__availability-status--${status}`]: true,
  });

  return <SvgIcon icon={status === 'available' ? 'check' : 'close'} className={styling} />;
};

const UnitReportLog: React.FC<IProps> = ({ log, unit }) => {
  const time = fromUTC(log.created_at).minus(fromUTC(unit.active_from).toMillis());

  const styling = classnames({
    'm-unit-log': true,
    [`m-unit-log--${log.availability_status}`]: true,
  });

  console.log('log:', log);

  return (
    <div className={styling}>
      <div className='m-unit-log__stats'>
        <AvailabilityStatistic weight={log.available || 0} type='available' />
        <AvailabilityStatistic weight={log.no_response || 0} type='unknown' />
        <AvailabilityStatistic weight={log.unavailable || 0} type='unavailable' />
      </div>
      <div className='m-unit-log__info'>
        <div className='m-unit-log__timing'>+{time.toFormat(time.hour > 0 ? 'TT' : 'mm:ss')}</div>
        {log.availability_status !== 'pending' && (
          <AvailableIcon status={log.availability_status} />
        )}
      </div>
    </div>
  );
};

export default UnitReportLog;
