import React from 'react';
import { IIncident } from 'core/api/incident';

export const IncidentContext = React.createContext<IIncident>({
  id: 0,
  identifier: '',
  place: '',
  location1: '',
  location2: '',
  paalnummer: '',
  active: 0,
  created_at: '',
  updated_at: '',
  units: [],
});
