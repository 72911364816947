import { AxiosError } from 'axios';
import { ISessionTokens } from 'store/session/sessionReducer';

export const isAxiosError = (error: object): error is AxiosError =>
  error.hasOwnProperty('response');

export const areSessionTokens = (tokens: object | undefined): tokens is ISessionTokens =>
  typeof tokens !== 'undefined' &&
  tokens.hasOwnProperty('accessToken') &&
  tokens.hasOwnProperty('expires') &&
  tokens.hasOwnProperty('refreshToken');
