import React from 'react';
import { Dispatch } from 'redux';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { AxiosResponse } from 'axios';

import httpClient, { setToken } from 'services/http';
import { IAction } from 'store/actions';
import { doLogin } from 'store/session/sessionActions';
import { getAuthCredentials } from 'utils/auth';
import { IAuthByPassword, ITokenResponse } from 'core/api/auth';
import { IUser } from 'core/api/user';
import Button from 'components/Button/Button';

interface IFormValues {
  username: string;
  password: string;
}

type Props = InjectedFormProps<IFormValues>;

const LoginForm: React.FC<Props> = (props) => {
  return (
    <form className='m-form' onSubmit={props.handleSubmit}>
      <div className='m-form__item'>
        <label className='m-form__label' htmlFor='username'>
          Gebruikersnaam
        </label>
        <Field
          id='username'
          name='username'
          type='text'
          component='input'
          placeholder='Gebruikersnaam'
          className='m-form__field'
        />
      </div>

      <div className='m-form__item'>
        <label className='m-form__label' htmlFor='username'>
          Wachtwoord
        </label>
        <Field
          id='password'
          name='password'
          type='password'
          component='input'
          placeholder='Wachtwoord'
          className='m-form__field'
        />
      </div>

      <div className='m-form__actions'>
        <Button className='m-form__action' type='submit'>
          Inloggen
        </Button>
      </div>
    </form>
  );
};

const handleSubmit = async (values: IFormValues, dispatch: Dispatch<IAction>): Promise<void> => {
  const authCredentials = getAuthCredentials();

  const payload: IAuthByPassword = {
    ...authCredentials,
    ...values,
    grant_type: 'password',
    scope: '*',
  };

  console.log('payload:', payload);

  const { data: token }: AxiosResponse<ITokenResponse> = await httpClient.post(
    '/oauth/token',
    payload,
  );

  setToken(token.access_token);

  const { data: user }: AxiosResponse<IUser> = await httpClient.get('/v1/user');

  dispatch(doLogin({ token, user }));
};

export default reduxForm<IFormValues>({
  form: 'login',
  onSubmit: handleSubmit,
})(LoginForm);
