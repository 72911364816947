import { Action } from 'redux';

export interface IAction extends Action<ActionType> {
  payload?: any;
}

export interface IActionCreator<T extends ActionType, P> extends Action<T> {
  payload: P;
}

export type ActionCreator<T extends ActionType, P> = (payload: P) => IActionCreator<T, P>;

export enum ActionType {
  LOGIN,
  LOGOUT,

  TOGGLE_COLOR_SCHEME,
}
