import React from 'react';
import UnitContext from 'components/Unit/UnitContext';

const MockIncident: React.FC = () => (
  <div className='m-incident'>
    <div className='m-incident__upper'>
      <div className='m-incident__title-wrapper'>
        <h1 className='m-incident__title'>Laagleende¹</h1>
        <span className='m-incident__subtitle'>Stationsstraat ²</span>
      </div>

      <div className='m-incident__timer'>00:09:36³</div>
    </div>

    <ul className='m-incident__units'>
      <div className='m-incident__unit'>
        <div className='m-unit m-unit--available'>
          <h1 className='m-unit__title'>02-4831⁴</h1>
          <UnitContext information={['Laagleende ⁵', 'TS ⁶', '20 ⁷']} />
        </div>
      </div>
    </ul>
  </div>
);

export default MockIncident;
