import { selectColorScheme } from 'store/settings/settingsSelectors';
import { ColorScheme, colorMapping, colorVariables, ColorVariants } from './colors';
import { store } from 'store/store';

/**
 * Updates CSS variables based on a color scheme.
 *
 * @param scheme Color scheme to set the colors to.
 */
function setColorScheme(scheme: ColorScheme): void {
  const html = document.getElementsByTagName('html')[0];

  for (const variant in colorVariables) {
    const cssVariableName = colorVariables[variant as ColorVariants];
    const color = colorMapping[scheme][variant as ColorVariants];

    html.style.setProperty(cssVariableName, color);
  }
}

let currentValue: ColorScheme = selectColorScheme(store.getState());

/**
 * Check if an action was dispatched that changed the color scheme. If so,
 * apply these changes in the DOM.
 */
function handleChange(): void {
  const previousValue = currentValue;
  currentValue = selectColorScheme(store.getState());

  if (previousValue === currentValue) {
    return;
  }

  setColorScheme(currentValue);
}

store.subscribe(handleChange);
