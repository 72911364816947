import React from 'react';

interface IProps {
  information: string[];
}

const UnitContext: React.FC<IProps> = ({ information }) => {
  const blocks = information.map((info, index) => (
    <div key={index} className='m-unit__context-block'>
      <span className='m-unit__context-text'>{info}</span>
    </div>
  ));

  return <div className='m-unit__context'>{blocks}</div>;
};

export default UnitContext;
