import React from 'react';
import moment from 'moment';

interface IProps {
  input: moment.MomentInput;
}

class ElapsedTime extends React.Component<IProps> {
  private timer: number | undefined;

  public componentDidMount(): void {
    this.timer = window.setInterval(this.forceUpdate.bind(this), 1000);
  }

  public componentWillUnmount(): void {
    window.clearInterval(this.timer);
  }

  public render(): React.ReactNode {
    const now = moment().utc();
    const start = moment.utc(this.props.input);

    const diff = now.diff(start);
    const formatted = moment.utc(diff).format('HH:mm:ss');

    return formatted;
  }
}

export default ElapsedTime;
