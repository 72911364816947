import useRequest, { UseRequestOptions, UseRequestResponse } from './useRequest';
import { IList } from 'core/api/lists';

type UseListFetchOptions<T> = Omit<UseRequestOptions<T>, 'defaultValue'>;

const useListFetch = <T>(
  options: UseListFetchOptions<IList<T[]>>,
): UseRequestResponse<IList<T[]>> => {
  const opts: UseRequestOptions<IList<T[]>> = {
    ...options,
    defaultValue: {
      data: [],
      links: null,
      meta: null,
    },
  };

  return useRequest<IList<T[]>>(opts);
};

export default useListFetch;
