import React from 'react';
import communication, { SocketCallback } from 'services/communication';

export default function useEvent<T>(
  channel: string,
  event: string,
  callback: SocketCallback<T>,
): void {
  const memoizedCallback = React.useCallback(callback, []);

  React.useEffect(() => {
    communication.register(channel, event, memoizedCallback);

    return (): void => {
      communication.unregister(channel);
    };
  }, [channel, event, memoizedCallback]);
}
