import React from 'react';
import classnames from 'classnames';

import { IUnitRole } from 'core/socket/unitRoles';
import Unit from 'components/Unit';

interface IProps {
  units: IUnitRole[];
}

const IncidentUnits: React.FC<IProps> = ({ units }) => {
  const styling = classnames({
    'm-incident__units': true,
  });

  const listItems = units.map((unit) => (
    <div className='m-incident__unit' key={unit.id}>
      <Unit unit={unit} />
    </div>
  ));

  return <ul className={styling}>{listItems}</ul>;
};

export default IncidentUnits;
