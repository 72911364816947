/** @format */

import { clientID, clientSecret } from 'services/env';
import { isAuthCredentials, AuthCredentials } from 'core/api/auth';

export function getAuthCredentials(): AuthCredentials {
  const credentials = {
    client_id: clientID,
    client_secret: clientSecret,
  };

  if (!isAuthCredentials(credentials)) {
    throw new Error('Auth credentials unknown. Did you set the environment?');
  }

  return credentials;
}

/**
 * @param jwt JSON Web Token
 */
export const decodeJWT = (token: string): any => {
  const decodedToken = JSON.parse(atob(token.split('.')[1]));

  return decodedToken;
  // TODO: Implement this
};
