// The env injected by Webpack. The pre-defined strings here
// must be equal to the 'mode' set in the webpack config files.
enum EnvironmentMode {
  Development = 'development',
  Test = 'test',
  Production = 'production',
}

export const isDev = process.env.NODE_ENV === EnvironmentMode.Development;
export const isTest = process.env.NODE_ENV === EnvironmentMode.Test;
export const isProd = process.env.NODE_ENV === EnvironmentMode.Production;

export const socketURL = process.env.REACT_APP_SOCKET_URL as string;
export const backendURL = process.env.REACT_APP_BACKEND_URL as string;
export const clientID = process.env.REACT_APP_PASSPORT_CLIENT_ID as string;
export const clientSecret = process.env.REACT_APP_PASSPORT_CLIENT_SECRET as string;

console.log('Environment: ', process.env.NODE_ENV);
console.log('Socket URL:', socketURL);
console.log('Backend URL:', backendURL);
console.log('------------------------------');
