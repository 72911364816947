import React from 'react';
import classnames from 'classnames';

import { IIncident } from 'core/socket/incident';

import IncidentUnits from './components/IncidentUnits';
import ElapsedTime from '../ElapsedTime';

import './Incident.scss';
import { formatIncidentSubtitle } from 'utils/incidents';

interface IProps {
  incident: IIncident;
}

const Incident: React.FC<IProps> = ({ incident }) => {
  const styling = classnames({
    'm-incident': true,
    'm-incident--inactive': !incident.active,
  });

  return (
    <div className={styling}>
      <div className='m-incident__upper'>
        <div className='m-incident__title-wrapper'>
          <h1 className='m-incident__title'>{incident.place}</h1>

          <span className='m-incident__subtitle'>{formatIncidentSubtitle(incident)}</span>
        </div>

        <div className='m-incident__timer'>
          <ElapsedTime input={incident.created_at} />
        </div>
      </div>

      <IncidentUnits units={incident.unit_roles_active} />
    </div>
  );
};

export default Incident;
