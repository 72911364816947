import React from 'react';

import { IIncident } from 'core/api/incident';
import useListFetch from 'hooks/useListFetch';
import { chunkDates } from 'utils/dates';
import { sortObject } from 'utils/sorting';

import './Statistics.scss';
import StatisticsGroup from './components/StatisticsGroup';

const Statistics: React.FC = () => {
  const [request] = useListFetch<IIncident>({
    url: '/v1/incidents',
  });

  const dateChunks = chunkDates(request.data.data, 'created_at');

  console.log('Date chunks:', dateChunks);

  const groups = Object.keys(dateChunks).map((date) => (
    <StatisticsGroup key={date} date={date} incidents={dateChunks[date]} />
  ));

  return <div className='o-statistics'>{groups}</div>;
};

export default Statistics;
