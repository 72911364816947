import React from 'react';

import './CircleText.scss';

interface IProps {
  title: string | number;
  subtitle: string;
}

const CircleText: React.FC<IProps> = (props) => (
  <div className='a-circle-text'>
    <div className='a-circle-text__title'>{props.title}</div>
    <div className='a-circle-text__subtitle'>{props.subtitle}</div>
  </div>
);

export default CircleText;
