import axios from 'axios';
import { doLogout } from 'store/session/sessionActions';
import { store } from 'store/store';
import { backendURL } from './env';
import { isAxiosError } from 'utils/guard';

/**
 * Axios HTTP Client for performing HTTP-requests.This instance contains
 * the user's authentication token which is included in the headers.
 */
const httpClient = axios.create({
  baseURL: backendURL,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
  },
});

httpClient.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    if (isAxiosError(error) && typeof error.response !== 'undefined') {
      if (error.response.status === 401) {
        store.dispatch(doLogout);
      }
    }

    // Do something with response error
    return Promise.reject(error);
  },
);

/**
 * Uses the given (bearer) token in the Authentication header of our axios
 * HTTP client.
 *
 * @param token Authentication token to use.
 */
export const setToken = (token: string): void => {
  httpClient.defaults.headers.Authorization = `Bearer ${token}`;
};

/**
 * Delete's the Authorization header of our HTTP-client.
 */
export const unsetToken = (): void => {
  delete httpClient.defaults.headers.Authorization;
};

export default httpClient;
