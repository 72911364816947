import { DateTime } from 'luxon';

interface IDateChunks<T> {
  [isoDate: string]: T[];
}

export const fromUTC = (date: string): DateTime =>
  DateTime.fromISO(date, { zone: 'utc' }).setLocale('nl');

export const chunkDates = <T extends object>(objects: T[], column: keyof T): IDateChunks<T> =>
  objects.reduce<IDateChunks<T>>((chunks, obj) => {
    // Retrieve the column that contains a date (like 'created_at').
    const dateColumn = obj[column];

    // Retrieve the ISO date (i.e. 2017-04-20) of the column.
    const iso = fromUTC(String(dateColumn));
    const isoDate = iso.toISODate();

    if (!chunks.hasOwnProperty(isoDate)) {
      chunks[isoDate] = [obj];
    } else {
      chunks[isoDate].push(obj);
    }

    return chunks;
  }, {});
