/**
 * CSS variable enumeration
 */
export type ColorVariants = 'red' | 'green';

export const colorVariables: { [key in ColorVariants]: string } = {
  red: '--color-red',
  green: '--color-green',
};

/**
 * All available color schemes in the application.
 */
export enum ColorScheme {
  Normal,
  Protanopia, // Red-Blind
  Deuteranopia, // Green-Blind
  Tritanopia, // Blue-Blind
  Protanomaly, // Red-Weak
  Deuteranomaly, // Green-Weak
  Tritanomaly, // Blue-Weak
  Achromatopsia, // Monochromacy
  Achromatomaly, // Blue Cone Monochromacy
}

type ColorMapping = {
  [index in ColorScheme]: {
    [key in ColorVariants]: string;
  };
};

export const colorMapping: ColorMapping = {
  [ColorScheme.Normal]: {
    red: '#FD0023',
    green: '#0FB923',
  },
  [ColorScheme.Protanopia]: {
    red: '#BBBE00',
    green: '#848769',
  },
  [ColorScheme.Deuteranopia]: {
    red: '#C1D100',
    green: '#807772',
  },
  [ColorScheme.Tritanopia]: {
    red: '#F80027',
    green: '#4A8588',
  },
  [ColorScheme.Protanomaly]: {
    red: '#E29600',
    green: '#5E9D52',
  },
  [ColorScheme.Deuteranomaly]: {
    red: '#E38510',
    green: '#5DA453',
  },
  [ColorScheme.Tritanomaly]: {
    red: '#F90127',
    green: '#39A35C',
  },
  [ColorScheme.Achromatopsia]: {
    red: '#929292',
    green: '#939393',
  },
  [ColorScheme.Achromatomaly]: {
    red: '#CC6E70',
    green: '#70A673',
  },
};
