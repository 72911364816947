import React from 'react';
import SvgIcon from 'components/SvgIcon';
import { Link } from 'react-router-dom';

import './MenuCard.scss';

interface IProps {
  title: string;
  icon: string;
  path: string;
}

const MenuCard: React.FC<IProps> = (props) => {
  return (
    <Link className='a-menu-card' to={props.path}>
      <SvgIcon icon={props.icon} className='a-menu-card__icon' />
      <span className='a-menu-card__label'>{props.title}</span>
    </Link>
  );
};

export default MenuCard;
