import React from 'react';
import './InfoBlock.scss';

interface IProps {
  subject: React.ReactNode;
}

const InfoBlock: React.FC<IProps> = (props) => (
  <div className='a-info-block'>
    <div className='a-info-block__subject'>{props.subject}</div>
    <div className='a-info-block__explanation'>{props.children}</div>
  </div>
);

export default InfoBlock;
