import React from 'react';
import { useClickAway, useKey } from 'react-use';
import classnames from 'classnames';

import useRootLevelPortal from 'hooks/useRootLevelPortal';
import './Aside.scss';

interface IProps {
  visible: boolean;
  onClickOutside?(event: KeyboardEvent): void;
}

type Props = IProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

const Aside: React.FC<Props> = (props) => {
  const wrapperRef = React.useRef<HTMLElement>(document.createElement('aside'));

  const handleCloseEvents = (event: KeyboardEvent): void => {
    if (typeof props.onClickOutside === 'function') {
      props.onClickOutside(event);
    }
  };

  useKey('Escape', handleCloseEvents);
  useClickAway(wrapperRef, handleCloseEvents);

  React.useEffect(() => {
    const styling = classnames({
      't-aside': true,
      't-aside--visible': props.visible,
    });

    wrapperRef.current.setAttribute('class', styling);
  }, [props.visible]);

  return useRootLevelPortal(wrapperRef)(props.children);
};

Aside.defaultProps = {
  visible: false,
  onClickOutside: undefined,
};

export default Aside;
