import React from 'react';

import Aside from 'containers/Aside';

import Login from 'containers/Login/Login';
import InfoBlock from 'components/InfoBlock/InfoBlock';
import MockIncident from 'components/Mock/MockIncident';

interface IProps {
  visible: boolean;
  close(): void;
}

const AsideMenu: React.FC<IProps> = (props) => {
  return (
    <Aside visible={props.visible} onClickOutside={props.close}>
      <div className='m-menu__inner'>
        <div className='m-menu__block'>
          <h1>Informatie</h1>
          <section>
            <h3>PreCom</h3>
            <p>
              Na ongeveer 45 tot 60 seconden komt wordt de status vanuit PreCom verstuurd. De status
              wordt aangepast naar groen (inzetbaar), rood (niet inzetbaar) of deze blijft grijs. In
              dat geval wordt er binnen PreCom intern opgeschaald en zal het nog eens 45 tot 60
              seconden duren voordat de volgende status vanuit PreCom wordt verstuurd.
            </p>
          </section>
          <section>
            <h3>Brandweerrooster</h3>
            <p>
              Bij brandweerrooster wordt elke 2 tot 3 seconden de huidige status opgehaald. Zodra de
              eenheid inzetbaar is wordt deze groen. Tot die tijd blijft de status grijs. Na 3
              minuten wordt de status rood.
            </p>
          </section>
          <section>
            <h3>wiib</h3>
            <p>
              Ook bij wiib wordt elke 3 tot 3 seconden de huidige status opgehaald. Zodra de eenheid
              inzetbaar is wordt deze groen. Tot die tijd blijft de status grijs. Na 3 minuten wordt
              de status rood.
            </p>
          </section>
        </div>

        <div className='m-menu__block'>
          <InfoBlock subject={<MockIncident />}>
            <ol>
              <li>Plaatsnaam</li>
              <li>Locatie</li>
              <li>Tijd sinds aanmaken incident</li>
              <li>Eenheidscode</li>
              <li>Kazerne</li>
              <li>Inzetrol</li>
              <li>Aantal beschikbaar voor inzetrol</li>
            </ol>
          </InfoBlock>
        </div>

        <div className='m-menu__block m-menu__block--bottom'>
          <Login />
        </div>
      </div>
    </Aside>
  );
};

export default AsideMenu;
