import React from 'react';
import classnames from 'classnames';

import './Button.scss';

export interface IButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: 'large' | 'medium' | 'small';
  theme?: 'primary';
}

const Button: React.FC<IButtonProps> = ({ className, size, theme, ...rest }) => {
  const styling = classnames({
    'a-btn': true,
    [`a-btn--size-${size}`]: size,
    [`a-btn--theme-${theme}`]: typeof theme === 'string',
    [className as string]: typeof className === 'string',
  });

  return <button {...rest} className={styling} />;
};

Button.defaultProps = {
  size: 'medium',
  theme: 'primary',
};
export default Button;
