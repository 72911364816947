/** @format */

type GrantType = 'password' | 'refresh_token';

export interface AuthCredentials {
  client_id: string;
  client_secret: string;
}

interface AuthBase<T = GrantType> extends AuthCredentials {
  grant_type: T;
  scope: '*';
}

export interface IAuthByPassword extends AuthBase<'password'> {
  username: string;
  password: string;
}

export interface IAuthByRefresh extends AuthBase<'refresh_token'> {
  refresh_token: string;
}

export function isAuthCredentials(data: Partial<AuthCredentials>): data is AuthCredentials {
  return typeof data.client_id === 'string' && typeof data.client_secret === 'string';
}

export interface ITokenResponse {
  token_type: 'Bearer';
  expires_in: number;
  access_token: string;
  refresh_token: string;
}
