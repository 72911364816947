import React from 'react';
import { IIncident } from 'core/api/incident';
import IncidentReport from './IncidentReport';
import CircleText from 'components/CircleText/CircleText';
import { fromUTC } from 'utils/dates';

interface IProps {
  date: string;
  incidents: IIncident[];
}

const StatisticsGroup: React.FC<IProps> = ({ date, incidents }) => {
  const statisticItems = incidents.map((incident) => (
    <div key={incident.id} className='m-timeline__item'>
      <IncidentReport incident={incident} />
    </div>
  ));

  const luxonDate = fromUTC(date);

  return (
    <div className='o-statistics__group'>
      <div className='m-timeline'>
        <div className='m-timeline__upper'>
          <CircleText title={luxonDate.day} subtitle={luxonDate.monthShort.replace('.', '')} />
        </div>

        <div className='m-timeline__content'>{statisticItems}</div>
      </div>
    </div>
  );
};

export default StatisticsGroup;
