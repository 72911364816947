import { unsetToken } from 'services/http';

export function uponLogin(): void {
  console.log('Logged in');
}

export function uponLogout(): void {
  console.log('Logged out');
  unsetToken();
}
